import Cookies from 'js-cookie'

const CookieKeys = [
  { key: 'ACCESS_TOKEN', value: 'access-token' },
  { key: 'REFRESH_TOKEN', value: 'refresh-token' },
  { key: 'UUID', value: 'uuid' },
  { key: 'ROLES', value: 'roles' },
  { key: 'EMAIL', value: 'email' },
  { key: 'TRAINING_ID', value: 'training-id' }
]

export function getCookie(cookieKey) {
  var cookie = CookieKeys.find(c => c.key === cookieKey)
  return (cookie) ? Cookies.get(cookie.value) : ''
}

export function setCookie(cookieKey, value) {
  var cookie = CookieKeys.find(c => c.key === cookieKey)
  return (cookie) ? Cookies.set(cookie.value, value) : ''
}

export function removeCookie(cookieKey) {
  var cookie = CookieKeys.find(c => c.key === cookieKey)
  return (cookie) ? Cookies.remove(cookie.value) : ''
}

