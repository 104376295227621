<template>
  <div id="app">
    <router-view :key="key"/>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
  }, computed: {
    key() {
      return this.$route.name?this.$route.name+new Date():this.$route+new Date()
    }
  }
};
</script>
