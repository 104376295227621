import axios from 'axios'
import store from '@/store'
import _ from 'lodash'
import { getCookie } from '@/utils/cookie'
import Vue from 'vue'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout 2 mins
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
    //  // let each request carry token
    //  // ['X-Token'] is a custom headers key
    //  // please modify it according to the actual situation
    //  config.headers['X-Token'] = getToken()
    // }
    // config.headers['Access-Control-Allow-Origin'] = '*'
    return config
  },
  error => {
    // do something with request error
    // console.log('Error from request', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const refreshToken = getCookie('REFRESH_TOKEN')
    // console.log('URL', response.config.url)

    /* if (!(_.endsWith(response.config.url, '/users/auth') && (response.config.method === 'patch' || response.config.method === 'post'))
    && !(_.endsWith(response.config.url, '/users/auth/forgot')
    && !response.config.url.includes('/info/app-init?lang'))
    ) */
    if (!response.config.url.includes('/info/app-init?lang') &&
      !(_.endsWith(response.config.url, '/user/auth') && response.config.method === 'patch') && // login
      !(_.endsWith(response.config.url, '/user/auth') && response.config.method === 'post') && // refresh token
      !(_.endsWith(response.config.url, '/auth') && response.config.method === 'patch') && // localhost login
      !(_.endsWith(response.config.url, '/auth') && response.config.method === 'post') // localhost refresh token
    ) {
      // console.log('RUN REFRESH TOKEN')

      store.dispatch('user/refreshToken', refreshToken).then(() => {
      }).catch(err => {
        console.log('refresh token after each api', err)
      })
    }

    // console.log('response-axios', response)

    return response
  },
  error => {
    // console.log('response-axios-err', error)
    // console.log('response-axios-err', error.response)

    if (typeof error.response === 'undefined') return Promise.reject(error)
    if (typeof error.response.status === 'undefined') return Promise.reject(error)

    if (error.response.status === 401) {
      // if the api access denied or token timeout, if just aoi access denied , redirect to dashboard page.
      // otherwise force logout
      if (error.response.config.url.indexOf('users/me') >= 0) {
        // console.log('response-axios-err', error.response)
        store.dispatch('user/logout').then(() => {
          // console.log('frontend logout', '')
          location.reload() /* 为了重新实例化vue-router对象 避免bug*/
          // Vue.prototype.$alert({
          //   title: 'Error',
          //   content: 'The token has expired, Please login again'
          // }).then(succcess => {
          //   location.reload() /* 为了重新实例化vue-router对象 避免bug*/
          // })
        })
      } else {
        store.dispatch('user/redirectLandingPage')
      }
    }

    return Promise.reject(error)
  }
)

export default service
