import { BToast } from 'bootstrap-vue'
export function showDebugLog(caption, content) {
  if (process.env.VUE_APP_ENV === 'development') {
    console.log(caption, content)
  }
}

export function getErrorMessage(errorObj) {
  const msg = (typeof errorObj.response === 'undefined') ? errorObj.toString() : errorObj.response.data.message
  return msg
}

export function displayPopupMessage(errorObj, type) {
  // console.log('error type', typeof errorObj)
  // console.log('error ', errorObj)
  let msg = ''
  if (typeof errorObj.response === 'undefined') {
    msg = errorObj.toString()
  } else if (typeof errorObj.response.data.stack !== 'undefined') {
    msg = errorObj.response.data.stack
  } else if (typeof errorObj.response.data.message !== 'undefined') {
    msg = errorObj.response.data.message
  } else {
    msg = 'Internal Error'
  }
  let bootStrapToaster = new BToast();
  bootStrapToaster.$bvToast.toast(msg, {
    title: 'Error',
    toaster: "b-toaster-top-center",
    solid: true,
    variant: 'danger',
    appendToast: false,
    autoHideDelay: 2000
  })

}
