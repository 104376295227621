import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    { path: '/', redirect: '/page?key=test' },
    {
        path: '/login',
        component: () =>
            import("../views/Login.vue"),
        meta: { title: 'Login' }
    },
    {
        path: '/forbidden',
        component: () =>
            import('@/views/error-page/401'),
        hidden: true,
        meta: { title: 'Forbidden' }
    },
    {
        path: '/not-found',
        component: () =>
            import('@/views/error-page/404'),
        hidden: true,
        meta: { title: 'Not Found' }
    },

]

const createRouter = () => new Router({
    //mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})


/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    {
        path: "/page",
        name: "ResourceList",
        component: () => import("../views/ResourceList.vue"),
        //roles: ['root', 'otr-digital-producer']
    },
    {
        path: '*',
        redirect: '/not-found',
        hidden: true
    }]

const router = createRouter()
// console.log('router', router)
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
