module.exports = [{
    date: '2023-08-17',
    version: '1.1.0',
    description: '<ul>' +
        '<li>Update range color : Resource Portal.</li>' +
        '</ul>'
}, {
    date: '2023-03-28',
    version: '1.0.0',
    description: '<ul>' +
        '<li>Resource Portal - Basic setup.</li>' +
        '<li>Resource Portal - LifeHikes login page.</li>' +
        '<li>Resource Portal - Display content from CMS API.</li>' +
        '<li>Resource Portal - Finalise the UI.</li>' +
        '</ul>'
}]